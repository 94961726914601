import * as React from "react"
import styled from "styled-components"

interface IProps {
  size: number
  src?: string
  text: string
}

// Implementation mostly ported from Fresco.
export default function Avatar({ size, src, text }: IProps) {
  const [imageLoaded, setImageLoaded] = React.useState(false)

  let content: React.ReactNode
  if (!src) {
    content = <Text>{text}</Text>
  } else if (imageLoaded) {
    content = <Image src={src} alt={text} />
  } else {
    content = (
      <>
        <Text>{text}</Text>
        <Image src={src} alt={""} onLoad={() => setImageLoaded(true)} style={{ display: "none" }} />
      </>
    )
  }

  return <Container style={{ "--avatar-size": `${size}px` } as React.CSSProperties}>{content}</Container>
}

const Container = styled.div`
  position: relative;
  width: var(--avatar-size);
  height: var(--avatar-size);
  overflow: hidden;
  border-radius: 50%;
`

const Image = styled.img`
  position: absolute;
  width: var(--avatar-size);
  height: var(--avatar-size);
  object-fit: cover;
`

const Text = styled.div`
  position: absolute;
  width: var(--avatar-size);
  height: var(--avatar-size);
  background: #09f;
  color: #fff;
  text-align: center;
  line-height: var(--avatar-size);
  font-size: 12px;
  font-weight: 600;
`
