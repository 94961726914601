import { Button, Stack } from "@framerjs/fraction"
import * as React from "react"
import { useParams } from "react-router-dom"
import { InputField, NewsletterField, TermsAndConditionsField } from "../components/form-fields"
import Intro from "../components/intro"
import { getSignUpState } from "../util/api"
import Loading from "./loading"
import styled from "styled-components"

export interface ICompleteSignupData {
  token: string
  email: string
  firstName: string
  lastName: string
  newsletter: boolean
  terms: boolean
}

type FieldName = Exclude<keyof ICompleteSignupData, "token" | "email">

interface IFormError {
  name: FieldName
  message: string
}

interface IProps {
  onComplete: (data: ICompleteSignupData) => void
  onError: (error: Error) => void
}

interface IRouterParams {
  token: string
}

export default function CompleteSignUp({ onError, onComplete }: IProps) {
  const { token } = useParams<IRouterParams>()
  const [signupData, setSignupData] = React.useState<ICompleteSignupData | null>(null)
  const [errors, setErrors] = React.useState<IFormError[]>([])

  // Get the current SSO state (user info)
  React.useEffect(() => {
    const checkSSOTokenState = async (ssoToken: string) => {
      try {
        const response = await getSignUpState(ssoToken)
        setSignupData({
          email: response.extra.email,
          firstName: response.extra.firstName,
          lastName: response.extra.lastName,
          token: ssoToken,
          newsletter: false,
          terms: false,
        })
      } catch (e) {
        const error = e instanceof Error ? e : Error(String(e))
        onError(error)
      }
    }
    if (token) {
      checkSSOTokenState(token)
    }
  }, [token, setSignupData, onError])

  if (!signupData) {
    return <Loading />
  }

  // eslint-disable-next-line
  const onSubmit = async (event: React.FormEvent<any>) => {
    event.preventDefault()
    const errs = validateCompleteSignupFormData(signupData)
    if (errs.length === 0) {
      onComplete(signupData)
      return
    }
    setErrors(errs)
  }

  const onChangeNewsletter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSignupData({ ...signupData, newsletter: e.currentTarget.checked })
  }

  const onChangeTerms = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSignupData({ ...signupData, terms: e.currentTarget.checked })
  }

  const onChangeFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSignupData({ ...signupData, firstName: e.currentTarget.value })
  }

  const onChangeLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSignupData({ ...signupData, lastName: e.currentTarget.value })
  }

  const getFieldError = (name: FieldName): string | undefined => errors.find((e) => e.name === name)?.message

  return (
    <>
      <Intro heading="Create Account" />
      <Form noValidate={true} autoComplete="off" onSubmit={onSubmit}>
        <Stack direction="row" gap={10}>
          <InputField
            name="firstName"
            placeholder="First name"
            value={signupData.firstName}
            onChange={onChangeFirstName}
            error={getFieldError("firstName")}
          />
          <InputField
            name="lastName"
            placeholder="Last name"
            value={signupData.lastName}
            onChange={onChangeLastName}
            error={getFieldError("lastName")}
          />
        </Stack>
        <InputField name="email" value={signupData.email} disabled />

        <Button type="submit" variant="primary" fluid>
          Continue
        </Button>

        <CheckboxesContainer>
          <TermsAndConditionsField checked={signupData.terms} onChange={onChangeTerms} error={getFieldError("terms")} />
          <NewsletterField onChange={onChangeNewsletter} checked={signupData.newsletter} />
        </CheckboxesContainer>
      </Form>
    </>
  )
}

function validateCompleteSignupFormData(formData: ICompleteSignupData): IFormError[] {
  const errors: IFormError[] = []

  if (!formData.terms) {
    errors.push({
      name: "terms",
      message: "Please accept the terms of service",
    })
  }

  if (formData.firstName.trim() === "") {
    errors.push({
      name: "firstName",
      message: "Can’t be empty",
    })
  }

  if (formData.lastName.trim() === "") {
    errors.push({
      name: "lastName",
      message: "Can’t be empty",
    })
  }

  return errors
}

const Form = styled.form`
  padding-top: 30;
  margin-top: 30;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const CheckboxesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;

  input[type="checkbox"] {
    background-color: rgba(255, 255, 255, 0.15) !important;
    border-color: transparent !important;
  }

  input[type="checkbox"]:checked {
    background-color: #09f !important;
  }

  label {
    color: rgba(255, 255, 255, 0.6);
    font-size: 15px;
    line-height: 1.4;
    text-align: left;
  }
`
