import * as React from "react"
import Loading from "./loading"
import { Stack, Button, Text } from "@framerjs/fraction"
import { IUser, getInvite, getLoggedInUser, IUserInvite } from "../util/api"
import Intro from "../components/intro"
import { ApiError } from "../util/errors"
import { Divider } from "../components/divider"
import { ButtonPlain } from "../components/button-plain"

interface IProps {
  inviteId: string
  onAcceptInvite: (inviteId: string) => void
  onInvalidInvite: (e: ApiError, isLoggedIn: boolean) => void
  onSwitchAccount: (inviteId: string) => void
}

export default function Invite({ inviteId, onAcceptInvite, onInvalidInvite, onSwitchAccount }: IProps) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [invite, setInvite] = React.useState<IUserInvite | null>(null)
  const [loggedInUser, setLoggedInUser] = React.useState<IUser | null>(null)

  React.useEffect(() => {
    if (!inviteId) {
      return
    }

    const fetchInvite = async (id: string) => {
      const user = await getLoggedInUser()

      try {
        const inviteData = await getInvite(id)
        if (!user) {
          return onSwitchAccount(inviteData.id)
        }
        setLoggedInUser(user)
        setInvite(inviteData)
      } catch (e) {
        onInvalidInvite(e.message, !!user)
      }
    }

    fetchInvite(inviteId)
  }, [inviteId, setLoggedInUser, onInvalidInvite, onSwitchAccount])

  if (!invite || !loggedInUser) {
    return <Loading />
  }

  // eslint-disable-next-line
  const onSubmit = async (e: React.FormEvent<any>) => {
    e.preventDefault()
    setIsLoading(true)
    onAcceptInvite(invite.id)
  }

  // eslint-disable-next-line
  const onClickContinueWithDifferentAccount = (e: React.MouseEvent<any>) => {
    e.preventDefault()
    onSwitchAccount(invite.id)
  }

  return (
    <>
      <Intro
        heading="Welcome to Framer"
        text={
          <>
            Design beautiful websites in minutes.
            <br />
            Sign up or sign in to get started.
          </>
        }
      />
      <Stack direction="column" gap={10}>
        <form noValidate={true} action="#" onSubmit={onSubmit}>
          <Stack direction="column" gap={10}>
            <Button type="submit" variant="primary" loading={isLoading} fluid>
              Continue as {loggedInUser.firstName}
            </Button>
            <Text size="xsmall" color={"#fff"} align="center">
              Signed in as {loggedInUser.email}
            </Text>
          </Stack>
        </form>

        <div style={{ padding: "20px 0px" }}>
          <Divider />
        </div>

        <ButtonPlain variant="primary" onClick={onClickContinueWithDifferentAccount} fluid>
          Sign in with a different account
        </ButtonPlain>
      </Stack>
    </>
  )
}
