import * as React from "react"
import { A, Text } from "@framerjs/fraction"
import Intro from "../components/intro"
import PageContainer from "../components/page-container"

export default function PageNotFound() {
  return (
    <PageContainer>
      <Intro heading="Page Not Found" />
      <Text color={"#fff"} style={{ paddingTop: 20 }}>
        You do not have access to this page at this time. Please <A href="/">return to sign up</A> and complete your
        account setup.
      </Text>
    </PageContainer>
  )
}
