import { Button } from "@framerjs/fraction"
import styled from "styled-components"

export const ButtonPlain = styled(Button)`
  background: rgba(255, 255, 255, 0.15) !important;
  color: white !important;

  &:hover {
    background: rgba(255, 255, 255, 0.1) !important;
  }
`
