import * as React from "react"
import { Button, A, Text, Stack } from "@framerjs/fraction"
import { DASHBOARD_URL } from "../env"
import { ApiError } from "../util/errors"
import { navigateTo } from "../util/navigate"
import PageContainer from "../components/page-container"
import FramerLogo from "../components/framer-logo"

// Maps error codes to human readable content
const ERROR_MAPPING = {
  [ApiError.InviteFailure]: () => <>This link is now expired. Please ask the sender to send you another invite.</>,

  [ApiError.LoginExpired]: () => (
    <>
      Sign-in links expire after one hour. <br /> Please try signing in again to get a new link.
    </>
  ),

  [ApiError.TooManyActivations]: () => (
    <>
      Framer X can be used on up to 2 devices.
      <br /> To continue, reset your active devices
      <br /> from the <A href={DASHBOARD_URL}>Dashboard</A>.
    </>
  ),

  [ApiError.SignInFailure]: () => (
    <>
      Something unexpected happened on our end while signing you in. If this error persists please{" "}
      <A href="https://www.framer.com/support/contact/">contact support</A>.
    </>
  ),

  [ApiError.SignUpFailure]: () => (
    <>
      Something unexpected happened on our end while creating your account. If this error persists please{" "}
      <A href="https://www.framer.com/support/contact/">contact support</A>.
    </>
  ),

  [ApiError.DiscountEmailFailure]: () => (
    <>
      Something unexpected happened on our end while sending you the discount email. Please contact{" "}
      <A href="https://www.framer.com/support/contact/">contact support</A>.
    </>
  ),

  [ApiError.NetworkFailure]: () => (
    <>We were unable to sign you in. Please check your internet connection and try again.</>
  ),

  [ApiError.InviteDomainRestricted]: (err: ErrorWithExtra) => (
    <>
      Unable to join workspace. <br /> Only users with a{" "}
      {err.extra.domains.map((domain: string) => `@${domain}`).join(", ")} account can join this workspace.
    </>
  ),

  [ApiError.CliAuthFailure]: () => <>We were unable to sign you in. Please try again.</>,

  [ApiError.GetRecommendedTeamsFailure]: () => (
    <>
      There was an error finding workspace recommendations for you.
      <br />
      You can always join your recommended workspaces from the dashboard.
    </>
  ),

  [ApiError.JoinTeamsFailure]: () => (
    <>
      There was an error joining the workspaces you selected.
      <br />
      You can always join your recommended workspaces from the dashboard.
    </>
  ),

  [ApiError.CreateTeamFailure]: () => (
    <>
      There was an error creating the workspace.
      <br />
      You can always create a workspace from the dashboard.
    </>
  ),

  [ApiError.Generic]: () => (
    <>
      Something unexpected happened on our end. If this error persists, please{" "}
      <A href="https://www.framer.com/support/contact/">contact support</A>.
    </>
  ),
}

// A list of errors which should redirect to the dashboard instead of back to login.
const ERRORS_REDIRECTING_TO_DASHBOARD = {
  [ApiError.GetRecommendedTeamsFailure]: true,
  [ApiError.JoinTeamsFailure]: true,
  [ApiError.CreateTeamFailure]: true,
  [ApiError.UpdateTeamFailure]: true,
}

export interface ErrorWithExtra {
  message: string
  // eslint-disable-next-line
  extra?: any
}

interface IProps {
  error: ErrorWithExtra
  isAppError: boolean
  onRetry: () => void
}

export default function Errors({ error, isAppError, onRetry }: IProps) {
  let onClickRetry: () => void
  let buttonLabel: string

  if (isAppError) {
    onClickRetry = () => navigateTo("framer-x://?fut=")
    buttonLabel = "Retry in Framer X"
  } else if (ERRORS_REDIRECTING_TO_DASHBOARD[error.message]) {
    onClickRetry = () => navigateTo(DASHBOARD_URL)
    buttonLabel = "Go to Dashboard"
  } else {
    onClickRetry = onRetry
    buttonLabel = "Back to Sign In"
  }

  const templateFunc = ERROR_MAPPING[error.message] || ERROR_MAPPING[ApiError.Generic]
  const body = templateFunc(error)

  return (
    <PageContainer>
      <Stack direction="column" gap={10}>
        <FramerLogo />
        <Stack direction="column" gap={20}>
          <Text color={"#fff"}>{body}</Text>
          <Button onClick={onClickRetry} variant="primary">
            {buttonLabel}
          </Button>
        </Stack>
      </Stack>
    </PageContainer>
  )
}
