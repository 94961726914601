import * as React from "react"

const FramerLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="30">
      <path d="M-.14-.003h20.28v10.006H10Z" fill="#FFF" />
      <defs>
        <linearGradient id="a" x1=".49353226" x2=".50646774" y1="0" y2="1">
          <stop offset="0" stopColor="#0AF" />
          <stop offset="1" stopColor="#09F" />
        </linearGradient>
      </defs>
      <path d="M-.14 10.003H10L20.14 20.01H-.14Z" fill="url(#a)" />
      <defs>
        <linearGradient id="b" x1=".49552237" x2=".50447763" y1="0" y2="1">
          <stop offset="0" stopColor="#03F" />
          <stop offset="1" stopColor="#05F" />
        </linearGradient>
      </defs>
      <path d="M-.14 20.01H10v10.006Z" fill="url(#b)" />
    </svg>
  )
}

export default FramerLogo
