import { ALLOW_LOCALHOST_REDIRECT } from "../env"
import { isMobile } from "./environment"

/** @see https://github.com/framer/FramerUserService/blob/d19e5c666f0f7398b97bafaa6a0bb6374d53be63/src/models/User.ts#L9 for accepted values */
export enum Origin {
  web = "web",
  mobile = "web-mobile",
  electronApp = "framer-app",
}

type InitialOrigin = string | null

const EMAIL_PATTERN = /^[^@\s,]+@[^@\s,]+\.[^@\s,]+$/

export function isValidEmail(email: string) {
  return EMAIL_PATTERN.test(email.trim())
}

export function isValidDestinationUrl(url: string) {
  try {
    const { hostname } = new URL(url)
    if (ALLOW_LOCALHOST_REDIRECT) {
      // Allow localhost redirects in development environments only
      return (
        hostname === "framer.com" ||
        hostname.endsWith(".framer.com") ||
        hostname === "framerlocal.com" ||
        hostname.endsWith(".framerlocal.com") ||
        hostname === "localhost"
      )
    }
    return hostname === "framer.com" || hostname.endsWith(".framer.com")
  } catch (e) {
    return false
  }
}

export function isValidOrigin(origin: InitialOrigin): origin is Origin {
  return (
    typeof origin === "string" &&
    Object.keys(Origin)
      .map((key) => Origin[key])
      .includes(origin)
  )
}

export function getValidOrigin(origin: InitialOrigin): Origin {
  if (isValidOrigin(origin)) {
    return origin
  }

  if (isMobile()) return Origin.mobile

  return Origin.web
}

export function isAppOrigin(origin: Origin): boolean {
  return origin === Origin.electronApp
}
