import React from "react"
import { Route, Switch, useRouteMatch } from "react-router-dom"
import Spinner from "../../components/spinner"
import { redirectToRoute, replaceWithRoute } from "../../history"
import {
  getDefaultProjectLocation,
  getDomainConfiguration,
  getRecommendedTeams,
  IRecommendedTeam,
  ITeam,
} from "../../util/api"
import CreateTeam from "./create"
import JoinTeam from "./join"
import RecommendTeam from "./recommend"

enum TeamRoute {
  join = "join/",
  create = "create/",
  recommend = "recommend/",
}

interface ITeamProps {
  onComplete: (options?: { useDefaultRedirect?: boolean }) => void
  onError: (error: Error) => void
  loggedInUserEmail?: string
}

export default function Team({ onComplete, onError, loggedInUserEmail }: ITeamProps) {
  const match = useRouteMatch()
  const redirectToTeamRoute = React.useCallback(
    (teamRoute: TeamRoute) => redirectToRoute(`${match.path}${teamRoute}`),
    [match]
  )
  const replaceWithTeamRoute = React.useCallback(
    (teamRoute: TeamRoute) => replaceWithRoute(`${match.path}${teamRoute}`),
    [match]
  )

  const [recommendedTeams, setRecommendedTeams] = React.useState<IRecommendedTeam[] | undefined>()
  const [team, setTeam] = React.useState<ITeam | undefined>()

  React.useEffect(() => {
    const gatherTeamInfo = async () => {
      const domainConfiguration = await getDomainConfiguration()
      if (!domainConfiguration.isPrivateDomain) {
        // Go straight to completion
        onComplete()
        return
      }

      const { defaultTeam } = await getDefaultProjectLocation()
      if (defaultTeam) {
        // Already a default team? Then skip further team selection / setup steps
        onComplete({ useDefaultRedirect: true })
        return
      }

      const teams = await getRecommendedTeams()
      if (teams.length > 0) {
        setRecommendedTeams(teams)
        replaceWithTeamRoute(TeamRoute.join)
      } else {
        // If there are no teams to recommend, go straight to completion
        onComplete()
      }
    }

    gatherTeamInfo().catch(() => {
      onComplete()
    })
    // This effect is only meant to run once, at mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const createdTeamId = React.useRef<string | undefined>()

  const onCreateNewTeam = React.useCallback(() => {
    redirectToTeamRoute(TeamRoute.create)
  }, [redirectToTeamRoute])

  const onCreatedTeam = React.useCallback(
    (team: ITeam) => {
      createdTeamId.current = team.id
      setTeam(team)
      const canEnableAutoJoin = team.domainConfigurations.some(
        ({ isPrivateDomain, isAutoJoin }) => isPrivateDomain && isAutoJoin
      )
      if (canEnableAutoJoin) {
        replaceWithTeamRoute(TeamRoute.recommend)
        return
      }

      // User signed up with a public domain, example: @gmail.com. These users
      // can’t enable auto join for their teams.
      onComplete()
    },
    [replaceWithTeamRoute, onComplete]
  )

  if (!recommendedTeams) {
    return <Spinner />
  }

  return (
    <Switch>
      <Route exact={true} path={`${match.path}${TeamRoute.join}`}>
        <JoinTeam
          teams={recommendedTeams}
          onJoinedTeams={onComplete}
          onCreateNewTeam={onCreateNewTeam}
          onError={onError}
        />
      </Route>
      <Route exact={true} path={`${match.path}${TeamRoute.create}`}>
        <CreateTeam onCreatedTeam={onCreatedTeam} onError={onError} loggedInUserEmail={loggedInUserEmail} />
      </Route>
      <Route exact={true} path={`${match.path}${TeamRoute.recommend}`}>
        <RecommendTeam team={team} onError={onError} onUpdatedTeam={onComplete} onSkip={onComplete} />
      </Route>
    </Switch>
  )
}
