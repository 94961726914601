import { SENTRY_ENV, SENTRY_RELEASE } from "../env"
import { init, captureException } from "@sentry/browser"

export const initializeSentry = () => {
  if (SENTRY_ENV === "local") {
    return
  }
  init({
    environment: SENTRY_ENV,
    dsn: "https://6334759d5f8d49868b7d831eef45097b@sentry.io/1365185",
    release: SENTRY_RELEASE,
  })
}

// eslint-disable-next-line
export default (error: any) => {
  if (SENTRY_ENV === "local") {
    return console.error(error)
  }
  return captureException(error)
}
