import * as React from "react"
import Intro from "../../components/intro"
import { Stack, Button, Input } from "@framerjs/fraction"
import styled from "styled-components"
import { isValidEmail } from "../../util/validation"
import { FormError } from "../../components/form-fields"
import { SendInviteParams } from "../../util/api"

interface Props {
  sendEmails: (invite: Pick<SendInviteParams, "invitedEmail">) => Promise<void>
}

export default function Invite({ sendEmails }: Props) {
  const [email, setEmail] = React.useState<string>("")
  const [emailError, setEmailError] = React.useState<string | undefined>()

  const onSetEmail = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value)
    setEmailError(undefined)
  }, [])

  const onBlurEmail = React.useCallback(() => {
    if (!isValidEmail(email)) {
      setEmailError("Please enter a valid email address")
      return
    }
  }, [email])

  const onInvite = React.useCallback(async () => {
    if (!isValidEmail(email)) {
      setEmailError("Please enter a valid email address")
      return
    }
    await sendEmails({ invitedEmail: email })
  }, [sendEmails, email])

  return (
    <StyledStack distribute="center" gap={40} direction="column">
      <Intro
        heading="Invite Coworker"
        text="By inviting a coworker you’ll gift them 20% of Basic & Pro plans too."
        trim
      />
      <StyledStack gap={10}>
        <StyledStack gap={0}>
          <Input
            name="email"
            value={email}
            placeholder="coworker@email.com"
            onChange={onSetEmail}
            onBlur={onBlurEmail}
            error={!!emailError}
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck={false}
            style={{ marginTop: 20, backgroundColor: "rgba(0, 0, 0, 0.2)", color: "#fff" }}
          />
          <FormError error={emailError} />
        </StyledStack>
        <Button onClick={onInvite} variant="primary" style={{ width: "100%" }}>
          Invite
        </Button>
      </StyledStack>
    </StyledStack>
  )
}

const StyledStack = styled(Stack)`
  > div {
    width: 100%;
  }
`
