import * as React from "react"
import styled from "styled-components"
import { Button, A } from "@framerjs/fraction"

interface IProps {
  url: string
}

export default function BackButton({ url }: IProps) {
  return (
    <A href={url}>
      <Button size="small" kind="rounded">
        <ArrowSVG xmlns="http://www.w3.org/2000/svg" width="8" height="13">
          <path
            d="M 10 0 L 5 5 L 0 0"
            transform="translate(-1 4) rotate(90 5 2.5)"
            fill="transparent"
            strokeWidth="2"
            stroke="#BBBBBB"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </ArrowSVG>
        Back to Framer
      </Button>
    </A>
  )
}

const ArrowSVG = styled.svg`
  margin-right: 10px;
  position: relative;
  top: 1px;
`
