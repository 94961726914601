import { API_BASE_URL } from "../env"

const REFRESH_WINDOW = 60 * 1000 // Refresh one minute before expiry

type MaybeAccessToken = string | null

class AccessTokenClient {
  private accessToken?: MaybeAccessToken | Promise<MaybeAccessToken>
  private refreshTimer?: number

  /**
   * Returns an access token if the user is logged in. Otherwise, returns null.
   */
  async getAccessToken({ refresh = false }: { refresh?: boolean } = {}): Promise<MaybeAccessToken> {
    if (this.accessToken === undefined || refresh) {
      this.accessToken = this.renewAccessToken()
    }
    return this.accessToken
  }

  async withAuthorizationHeader(init: RequestInit): Promise<RequestInit> {
    const accessToken = await this.getAccessToken()

    init.headers = new Headers(init.headers)
    init.headers.set("authorization", `Bearer ${accessToken}`)

    return init
  }

  private async renewAccessToken(): Promise<MaybeAccessToken> {
    const result = await this.fetchAccessToken()

    if (result === null) {
      this.accessToken = null
      clearTimeout(this.refreshTimer)
      return null
    }

    const { accessToken, expiresAt } = result
    this.accessToken = accessToken
    this.setupRefreshTask(expiresAt)
    return accessToken
  }

  private setupRefreshTask(expiresAt: Date) {
    const refreshAt = new Date(expiresAt.getTime() - REFRESH_WINDOW).getTime()
    const refreshIn = refreshAt - Date.now()

    clearTimeout(this.refreshTimer)
    this.refreshTimer = setTimeout(() => this.renewAccessToken(), refreshIn)
  }

  private async fetchAccessToken(): Promise<{ accessToken: string; expiresAt: Date } | null> {
    const response = await fetch(`${API_BASE_URL}/auth/web/access-token`, { credentials: "include" })
    if (response.status === 401) {
      return null
    } else if (!response.ok) {
      throw new Error(`AccessTokenRefresher: response is not ok: ${response.status}`)
    }

    const { accessToken, expiresAt } = await response.json()
    if (typeof accessToken !== "string") {
      throw new Error("AccessTokenRefresher: invalid accessToken")
    }
    if (typeof expiresAt !== "string") {
      throw new Error("AccessTokenRefresher: invalid expiresAt")
    }

    return { accessToken, expiresAt: new Date(expiresAt) }
  }
}

export const accessTokenClient = new AccessTokenClient()
