import * as React from "react"
import { Button, Stack, Select, A, Text } from "@framerjs/fraction"
import { ITeam, updateTeam } from "../../util/api"
import Intro from "../../components/intro"

interface IProps {
  team: ITeam | undefined
  onSkip: () => void
  onUpdatedTeam: () => void
  onError: (error: Error) => void
}

export default function RecommendTeam({ team, onSkip, onUpdatedTeam, onError }: IProps) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [defaultRole, setDefaultRole] = React.useState<"viewer" | "editor">("editor")
  const autoJoinDomains = (team?.domainConfigurations || [])
    .filter(({ isPrivateDomain, isAutoJoin }) => isPrivateDomain && isAutoJoin)
    .map(({ domain }) => domain)

  const onSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if (!team) {
        return
      }
      setIsLoading(true)
      updateTeam({ ...team, configuration: { autoJoinDefaultRole: defaultRole, autoJoinTeam: true } })
        .then(() => {
          onUpdatedTeam()
        })
        .catch((err) => {
          setIsLoading(false)
          onError(err)
        })
    },
    [defaultRole, onError, onUpdatedTeam, team]
  )

  const onChangeHandler = React.useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.currentTarget.value
    if (value !== "viewer" && value !== "editor") {
      return
    }
    setDefaultRole(value)
  }, [])

  const handleOnSkip = React.useCallback(() => {
    if (isLoading) return
    onSkip()
  }, [onSkip, isLoading])

  if (autoJoinDomains.length < 1) {
    onSkip()
    return null
  }

  return (
    <div>
      <Intro
        icon={null}
        heading="Recommend workspace"
        text={
          <>
            Recommend the workspace to any users <br /> joining with a {autoJoinDomains.join(", ")} domain
          </>
        }
      />
      <form noValidate autoComplete="off" onSubmit={onSubmit}>
        <Stack direction="column" gap={15}>
          <Select onChange={onChangeHandler}>
            <option key="editor" value={"editor"}>
              Default as Editor
            </option>
            <option key="viewer" value={"viewer"}>
              Default as Viewer
            </option>
          </Select>

          <Button loading={isLoading} fluid variant="primary" type="submit">
            Continue
          </Button>
          <Text size="small" align="center">
            <A style={{ color: "#919191" }} onClick={handleOnSkip}>
              Skip this step
            </A>
          </Text>
        </Stack>
      </form>
    </div>
  )
}
