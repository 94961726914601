export enum ApiError {
  Generic = "GENERIC_ERROR",
  UserNotFound = "USER_NOT_FOUND",
  LoginExpired = "LOGIN_EXPIRED",
  TooManyActivations = "TOO_MANY_ACTIVATIONS",
  InviteFailure = "INVITE_FAIL",
  InviteDomainRestricted = "INVITE_DOMAIN_RESTRICTED",
  SignUpFailure = "SIGNUP_FAILURE",
  SignInFailure = "SIGNIN_FAILURE",
  NetworkFailure = "NETWORK_FAILURE",
  CliAuthFailure = "CLI_AUTH_FAILURE",
  GetRecommendedTeamsFailure = "GET_RECOMMENDED_TEAMS_FAILURE",
  JoinTeamsFailure = "JOIN_TEAMS_FAILURE",
  CreateTeamFailure = "CREATE_TEAM_FAILURE",
  UpdateTeamFailure = "UPDATE_TEAM_FAILURE",
  ToSConsentFailure = "TOS_CONSENT_FAILURE",
  DiscountEmailFailure = "DISCOUNT_EMAIL_FAILURE",
}

// Possible values of the 'error' query parameter,
enum QueryParamError {
  TooManyActivations = "Too many activations",
  LoginExpired = "expired",
}

// Maps a query string error to the equivalent ApiError, so that we can
// consistentlt handle these. Reason for this is external systems (Framer X)
// redirecting to login.framer.com, just for the purpose of displaying an error on the web
export const mapToApiError = (error: string): ApiError => {
  switch (error) {
    case QueryParamError.TooManyActivations:
      return ApiError.TooManyActivations
    case QueryParamError.LoginExpired:
      return ApiError.LoginExpired
    default:
      return ApiError.Generic
  }
}
