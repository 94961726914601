import React from "react"
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { RECAPTCHA_SITE_KEY } from "./env"
import captureError from "./util/captureError"

interface Props {
  children: React.ReactNode
}

export const RecaptchaProvider: React.FC<Props> = ({ children }) => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={RECAPTCHA_SITE_KEY || ""}
      useEnterprise
      scriptProps={{ async: true, defer: true }}
      container={{ element: "recaptcha-container", parameters: {} }}
    >
      <div id="recaptcha-container" style={{ display: "none" }}></div>
      {children}
    </GoogleReCaptchaProvider>
  )
}

export function useReCaptcha() {
  const { executeRecaptcha } = useGoogleReCaptcha()

  const generateToken = async (action: string): Promise<string | undefined> => {
    if (!executeRecaptcha) {
      captureError("Recaptcha is not initialized")
      return
    }
    try {
      const token = await executeRecaptcha(action)
      return token
    } catch (error) {
      captureError(error)
      return
    }
  }

  return { generateToken }
}
