import * as twemojiParser from "twemoji-parser"

// Borrowed from Fresco.

function extract(text: string): [string | null, string] {
  const [emojiEntity] = twemojiParser.parse(text)
  if (!emojiEntity || emojiEntity.indices[0] > 0) {
    return [null, text]
  }
  return [emojiEntity.url, text.substring(emojiEntity.indices[1]).trimLeft()]
}

export function extractInitials(text: string): string {
  const [, textWithoutEmoji] = extract(text)
  return textWithoutEmoji?.match(/./u)?.[0]?.toUpperCase() ?? ""
}
