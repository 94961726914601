import * as React from "react"
import styled from "styled-components"

import { Heading, Text } from "@framerjs/fraction"
import FramerLogo from "../components/framer-logo"

interface IProps {
  heading?: string
  text?: string | JSX.Element
  icon?: JSX.Element | null
  trim?: boolean
}

export default function Intro({ heading, text, icon = <FramerLogo />, trim }: IProps) {
  return (
    <IntroContainer className={trim ? "trim" : ""}>
      {icon && (
        <IconsContainer>
          <Icon>{icon}</Icon>
        </IconsContainer>
      )}
      {heading && (
        <Heading level={5} color={"rgba(255, 255, 255)"} style={{ fontWeight: 500 }}>
          {heading}
        </Heading>
      )}
      {text && <Subtitle style={{ marginTop: "10px", fontSize: "15px" }}>{text}</Subtitle>}
    </IntroContainer>
  )
}

const IntroContainer = styled.div`
  text-align: center;
  margin-bottom: 30px;

  &.trim {
    margin-bottom: 0;
  }

  p {
    text-wrap: balance; // Gives a warning but works great
  }
`

const Icon = styled.div`
  > * {
    display: block;
  }
`

const IconsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 30px;
`

const Subtitle = styled(Text)`
  opacity: 0.6;
  color: rgba(255, 255, 255);
`
