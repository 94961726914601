import * as React from "react"
import Intro from "../../components/intro"
import { Stack, Button, colors } from "@framerjs/fraction"
import styled from "styled-components"
import { replaceWithRoute } from "../../history"
import { MobileRoute } from "./routes"

const Step = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: rgba(255, 255, 255);
  border-radius: 20px;
  > * + * {
    margin-left: 15px;
  }
`

const StepDescription = styled.span`
  flex: 1;
  color: rgb(255, 255, 255);
  font-size: 15px;
  padding-left: 20px;
`

const LinkButton = styled(Button)`
  width: 80px;
  height: 44px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  align-content: center;
  border-radius: 12px;
  font-size: 15px;
`

enum MobileSignupOptions {
  Tutorial = "tutorial",
  Templates = "templates",
  Sites = "sites",
  Invite = "invite",
}

const steps: { description: string; buttonText: string; url: string; target: string; key: MobileSignupOptions }[] = [
  {
    description: "View sites made in Framer",
    buttonText: "View",
    key: MobileSignupOptions.Sites,
    url: "https://www.framer.com/gallery/",
    target: "_blank",
  },
  {
    description: "Watch a video tutorial",
    buttonText: "Watch",
    key: MobileSignupOptions.Tutorial,
    url: "https://www.youtube.com/watch?v=qSDQ3U6Qyaw",
    target: "_blank",
  },
  {
    description: "Browse templates",
    buttonText: "Browse",
    key: MobileSignupOptions.Templates,
    url: "https://www.framer.com/templates",
    target: "_blank",
  },
  {
    description: "Invite a coworker",
    buttonText: "Invite",
    key: MobileSignupOptions.Invite,
    url: "/mobile/invite",
    target: "_self",
  },
]

export default function MobileSteps() {
  const onSelectStep = React.useCallback(async (url: string, target: string, key: MobileSignupOptions) => {
    if (target === "_self") {
      replaceWithRoute(url)
      return
    }
    replaceWithRoute(MobileRoute.thanks)
    openNewTab(url)
  }, [])

  return (
    <Stack gap={40} direction="column">
      <Intro
        heading="Get Started"
        text="Framer doesn’t work on mobile just yet. To make up for it, we just sent you a 20% discount code for Basic & Pro plans."
        trim
      />
      <Stack direction="column" gap={10}>
        {steps.map((step, index) => (
          <Step
            key={step.key}
            style={{ backgroundColor: index === 0 ? "rgba(0, 153, 255, 0.2)" : "rgba(255, 255, 255, 0.1)" }}
          >
            <StepDescription color={colors.textBody}>{step.description}</StepDescription>
            <LinkButton
              variant="primary"
              style={{ backgroundColor: index === 0 ? "rgba(0, 153, 255, 1)" : "rgba(255, 255, 255, 0.15)" }}
              onClick={() => onSelectStep(step.url, step.target, step.key)}
            >
              {step.buttonText}
            </LinkButton>
          </Step>
        ))}
      </Stack>
    </Stack>
  )
}

function openNewTab(href: string, options: { referrer: boolean } = { referrer: true }) {
  const link = document.createElement("a")
  link.href = href
  link.rel = `${options.referrer ? "" : " noreferrer"}`
  link.target = "_blank"
  document.body.appendChild(link)
  link.click()
  link.remove()
}
