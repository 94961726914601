import { Button, Input } from "@framerjs/fraction"
import React, { useState } from "react"
import { FormError } from "../components/form-fields"
import Intro from "../components/intro"
import { isValidEmail } from "../util/validation"
import { ButtonPlain } from "../components/button-plain"
import styled from "styled-components"

interface IProps {
  onEmailValidated: (email: string) => void
  initiateGoogleSign: () => Promise<void>
  email?: string
}

export default function SignIn({ email: prefillEmail, onEmailValidated, initiateGoogleSign }: IProps) {
  const [email, setEmail] = useState<string>(prefillEmail || "")
  const [emailError, setEmailError] = React.useState<string | undefined>()
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [emailInputVisible, setEmailInputVisible] = React.useState<boolean>(false)

  // eslint-disable-next-line
  const onSubmit = async (e: React.FormEvent<any>) => {
    e.preventDefault()
    if (!isValidEmail(email)) {
      setEmailError("Please enter a valid email address")
      return
    }

    setIsLoading(true)
    await onEmailValidated(email)
    setIsLoading(false)
  }

  const onEmailInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value)
    setEmailError(undefined)
  }

  const openEmailForm = () => setEmailInputVisible(true)

  return (
    <div>
      <Intro heading="Welcome to Framer" />
      <FlexContainer>
        <Button onClick={initiateGoogleSign} icon="socialGoogle" variant="primary" fluid>
          Continue with Google
        </Button>

        {!emailInputVisible && (
          <ButtonPlain fluid onClick={openEmailForm} variant="primary">
            Continue with email
          </ButtonPlain>
        )}
      </FlexContainer>
      {emailInputVisible && (
        <Form noValidate action="#" onSubmit={onSubmit}>
          <Input
            error={!!emailError}
            type="email"
            placeholder="Email"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck={false}
            onChange={onEmailInputChange}
            value={email}
            autoFocus
            style={{ marginTop: 20, backgroundColor: "rgba(0, 0, 0, 0.2)", color: "#fff" }}
          />
          <FormError error={emailError} />

          <ButtonPlain type="submit" fluid variant="primary" loading={isLoading}>
            Continue
          </ButtonPlain>
        </Form>
      )}
    </div>
  )
}

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const Form = styled.form`
  padding-top: 30;
  margin-top: 30;
  display: flex;
  flex-direction: column;
  gap: 10px;
`
