import { Button, Stack } from "@framerjs/fraction"
import React from "react"
import { InputField } from "../../components/form-fields"
import Intro from "../../components/intro"
import { createTeam, ITeam } from "../../util/api"

const convertEmailToTeamName = (email?: string) => {
  if (!email || email.trim() === "") return ""

  const domain = email.split("@")[1].replace(/-/g, " ")
  const domainWithoutTLD = domain.split(".")[0]

  return domainWithoutTLD
}

interface ICreateTeamProps {
  onCreatedTeam: (team: ITeam) => void
  onError: (error: Error) => void
  loggedInUserEmail?: string
}

export default function CreateTeam({ onCreatedTeam, onError, loggedInUserEmail }: ICreateTeamProps) {
  const [teamName, setTeamName] = React.useState(convertEmailToTeamName(loggedInUserEmail))
  const [teamNameError, setTeamNameError] = React.useState<string | undefined>()
  const [isCreatingTeam, setIsCreatingTeam] = React.useState(false)

  const onTeamNameChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setTeamName(e.currentTarget.value)
  }, [])

  const onSubmit = React.useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      if (teamName.trim() === "") {
        setTeamNameError("Can’t be empty")
        return
      }

      setIsCreatingTeam(true)
      try {
        const team = await createTeam({ teamName: teamName })
        onCreatedTeam(team)
      } catch (err) {
        setIsCreatingTeam(false)
        onError(err)
      }
    },
    [teamName, onCreatedTeam, onError]
  )

  return (
    <>
      <Intro
        icon={null}
        heading="Create new workspace"
        text={
          <>
            Create a workspace to work with other
            <br />
            members and collaborate.
          </>
        }
      />
      <form noValidate={true} autoComplete="off" onSubmit={onSubmit}>
        <Stack direction="column" gap={15}>
          <InputField
            name={"teamName"}
            placeholder="Workspace Name"
            value={teamName}
            onChange={onTeamNameChange}
            error={teamNameError}
            autoFocus={true}
          />
          <Button type="submit" variant="primary" fluid loading={isCreatingTeam}>
            Create workspace
          </Button>
        </Stack>
      </form>
    </>
  )
}
