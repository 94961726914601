import * as React from "react"
import styled from "styled-components"
import { GlobalStyle } from "../style"
import { GlobalStyle as FractionGlobalStyle } from "@framerjs/fraction"
import DarkModeListener from "../DarkModeListener"
import BackButton from "./back-button"

interface IProps {
  children?: React.ReactNode
  backToFramerUrl: string | null
}

const Layout: React.FunctionComponent<IProps> = ({ children, backToFramerUrl }) => (
  <Wrapper>
    <Content>
      <DarkModeListener />
      <GlobalStyle />
      <FractionGlobalStyle fontLocation="/fonts" />
      {backToFramerUrl && (
        <BackButtonWrapper>
          <BackButton url={backToFramerUrl} />
        </BackButtonWrapper>
      )}

      {children}
    </Content>

    <Visual>
      <iframe src="https://signup2.framer.website" width="100%" height="100%" title="visual" />
    </Visual>
  </Wrapper>
)

export default Layout

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(155deg, #000000 0%, rgb(11, 0, 74) 38%, rgb(0, 73, 184) 75%, rgb(48, 131, 236) 100%);
`

const Visual = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
`

const Content = styled.main`
  z-index: 1;
  flex-grow: 1;
  padding: 40px;
  box-sizing: border-box;
  flex: 1;
  max-width: 460px;
  display: flex;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  flex-direction: column;
  backdrop-filter: blur(20px);
  border-radius: 20px;

  @media (max-width: 460px) {
    width: 100vw;
    height: 100vh;
    max-width: unset;
    padding: 20px;
    backdrop-filter: unset;
    background: linear-gradient(
      180deg,
      rgba(10, 10, 10, 0) 0%,
      rgb(12, 1, 77) 40%,
      rgb(4, 0, 107) 60%,
      rgba(26, 85, 201, 0) 100%
    );
  }
`

const BackButtonWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
`
