// FIXME: Find a better way to check this.
const safeNavigator = typeof navigator !== "undefined" ? navigator : undefined

const deviceType = () => {
  // https://github.com/jeffmcmahan/device-detective/blob/master/bin/device-detect.js
  if (safeNavigator && /tablet|iPad|Nexus 9/i.test(safeNavigator.userAgent)) return "tablet"
  if (safeNavigator && /mobi/i.test(safeNavigator.userAgent)) return "phone"
  return "desktop"
}

const isPhone = () => deviceType() === "phone"

const isTablet = () => deviceType() === "tablet"

export const isMobile = () => isPhone() || isTablet()
