import { createGlobalStyle } from "styled-components"
import { colors, palette, screen } from "@framerjs/fraction"

export const GlobalStyle = createGlobalStyle`
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
figure {
  display: inline-block;
}
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  line-height: 1;
}
ol,
ul,
li {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  text-decoration: none;
}
input {
  line-height: normal;
}

/* Base */
html,
body {
  box-sizing: border-box;
  height: 100%;
  background: var(--login-body-background, "#fff");

}
*,
*:before,
*:after {
  box-sizing: inherit;
}
:root {
  --login-body-background: ${palette.white};
  --text-color: ${palette.dark20};
  --fraction-color-textHeadline: ${palette.dark80};
  --fraction-color-inputDangerColor: ${colors.textHeadline};

  /* Hack to prevent error states from having red text in placeholders and selects element */
  .input::placeholder, .error .input::placeholder {
    --fraction-color-inputDangerColor: ${colors.inputPlaceholderColor};
  }
}

[data-theme="dark"] {
  --text-color: ${palette.dark20};
  --fraction-color-textBody: ${palette.white};
  --login-body-background: ${palette.dark94};
  --fraction-color-inputBackground: ${palette.dark80};
  --fraction-color-inputDangerColor: ${colors.textHeadline};

  /* Hack to prevent error states from having red text in placeholders and selects element */
  .input::placeholder {
    --fraction-color-inputDangerColor: ${colors.inputPlaceholderColor};
  }
  .error .input {
    --fraction-color-inputDangerColor: ${colors.textBody};
  }
}

/* Layout */
#root {
  height: 100%;
  width: 100%;
}

p a {
  font-weight: inherit !important;
}
.size-small {
  font-size: 15px !important;
}
.level-5 {
  font-size: 28px !important;
}
/* Hacky fix to ensure firstname and lastname
inputs in a <Stack> component take up the full width */
form .direction-row > div {
  width: 50%;
}

@media (${screen.tablet}) {
  main {
    min-width: 330px;
  }
}

/* Loading step */
.loading-spinner {
  --spinner-img: url("/images/spinner.png");

  background-color: #666;

  margin-left: auto;
  margin-right: auto;
  width: 30px;
  height: 30px;
  mask: var(--spinner-img);
  -webkit-mask: var(--spinner-img);
  mask-size: 30px;
  -webkit-mask-size: 30px;

  animation-name: spin;
  animation-duration: 800ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  100% {
    transform: rotate(360deg) translateZ(0);
  }
}

`
