import * as React from "react"
import styled from "styled-components"
import { colors } from "@framerjs/fraction"

const TextRuler = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 14px;
  color: ${colors.inputPlaceholderColor};
  line-height: 8px;
  margin: auto;

  ::before,
  ::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid ${colors.borderHighlight};
  }
  ::before {
    margin-top: 3px;
    margin-right: 1em;
  }
  ::after {
    margin-top: 3px;
    margin-left: 1em;
  }
`

const Ruler = styled.div`
  margin: auto;
  border-bottom: 1px solid ${colors.borderHighlight};
`

interface IProps {
  label?: string
}

export const Divider = ({ label }: IProps) => {
  if (label) {
    return <TextRuler>{label}</TextRuler>
  }
  return <Ruler />
}
