const projectIdRegex =
  /^\/projects\/(?<prefix>(?:(?:[A-Za-z0-9]+-)*[A-Za-z0-9]+--)?)(?<id>[A-Za-z0-9]{20})(?<accessToken>(?:-[A-Za-z0-9]+)?)/
const joinViaLinkRegex = /^\/join\//

/**
 * Determines if the redirect url should be respected. Currently we always want
 * to direct people to a new project unless we have a very specific destination
 * @param url url form of redirect param
 */
export function shouldUseDefaultRedirect(url: URL): boolean {
  if (url.searchParams.has("continue")) {
    return true
  }

  const matchProject = url.pathname.match(projectIdRegex)
  const matchJoinViaLink = url.pathname.match(joinViaLinkRegex)
  const matchDuplicateTemplate = url.pathname === "/projects/new" && url.searchParams.has("duplicate")

  return !!matchProject?.groups?.id || !!matchJoinViaLink || matchDuplicateTemplate
}
