import { useEffect } from "react"

const updateDOM = (isDark: boolean) => {
  // Switch data attribute to trigger friction's dark theme
  document.body.dataset.theme = isDark ? "dark" : ""

  // Update favicons according to preferred appearance
  const url = process.env.PUBLIC_URL + (isDark ? "/favicon-lite@2x.png" : "/favicon@2x.png")
  Array.from(document.querySelectorAll("link[rel='shortcut icon']")).forEach((link) => {
    link.setAttribute("href", url)
    link.setAttribute("color", isDark ? "#fff" : "#000")
  })
}

// Feature detect is to prevent test from failing
const isDarkQuery = "matchMedia" in window && window.matchMedia("(prefers-color-scheme: dark)")
const onChange = (event: MediaQueryListEvent) => updateDOM(event.matches)

export default function DarkModeListener() {
  useEffect(() => {
    if (!isDarkQuery) {
      return
    }
    updateDOM(isDarkQuery.matches)
    isDarkQuery.addListener(onChange)
    return () => {
      isDarkQuery.removeListener(onChange)
    }
  }, [])

  return null
}
