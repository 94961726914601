import * as React from "react"
import * as ReactDOM from "react-dom"
import { Router } from "react-router"
import { initializeSentry } from "./util/captureError"
import App from "./App"
import { Routes } from "./routes"
import { history, redirectToRoute } from "./history"
import { RecaptchaProvider } from "./RecaptchaProvider"

initializeSentry()

// Note: URLSearchParams uses application/x-www-form-urlencoded formate, so it
// will automatically decode the redirectUrl when its retrieved via the
// URLSearchParams.get(...) api. (source:
// https://url.spec.whatwg.org/#interface-urlsearchparams)
const params = new URLSearchParams(window.location.search)

if (params.has("token")) {
  history.replace(`${Routes.completeSignUp}${params.get("token")}/`)
}

if (params.has("invite")) {
  redirectToRoute(Routes.invite)
}

ReactDOM.render(
  <RecaptchaProvider>
    <Router history={history}>
      <App
        backToFramerUrl={params.get("ref") === "site" ? document.referrer : null}
        error={params.get("error")}
        invite={params.get("invite")}
        isInviteForNewUser={params.get("existing") === "false"}
        redirect={params.get("redirect")}
        source={params.get("source")}
        origin={params.get("origin")}
      />
    </Router>
  </RecaptchaProvider>,
  document.getElementById("root") as HTMLElement
)
