import React, { useEffect, useCallback } from "react"
import { Route, Switch, useRouteMatch } from "react-router-dom"
import MobileSteps from "./steps"
import Invite from "./invite-coworker"
import { redirectToRoute, replaceWithRoute } from "../../history"
import { Routes } from "../../routes"
import { IUser, SendInviteParams, requestDiscount, sendInvite } from "../../util/api"
import Intro from "../../components/intro"
import PageNotFound from "../page-not-found"
import { Button } from "@framerjs/fraction"
import { navigateTo } from "../../util/navigate"
import { MobileRoute } from "./routes"
import styled from "styled-components"

interface Props {
  loggedInUser: IUser | null
}
export default function Mobile({ loggedInUser }: Props) {
  const match = useRouteMatch()

  useEffect(() => {
    if (!loggedInUser) {
      redirectToRoute(Routes.signIn)
      return
    } else {
      requestDiscount({ email: loggedInUser.email }).catch(() => {
        redirectToRoute(`${Routes.mobile}${MobileRoute.thanks}`)
      })
    }
  }, [loggedInUser])

  const sendEmails = useCallback(
    async (invite: Pick<SendInviteParams, "invitedEmail">) => {
      if (!loggedInUser) return

      await sendInvite({ ...invite, senderName: `${loggedInUser.firstName} ${loggedInUser.lastName}` })
      replaceWithRoute(MobileRoute.thanks)
    },
    [loggedInUser]
  )

  if (!loggedInUser) {
    return null
  }

  return (
    <Switch>
      <Route exact path={`${match.path}${MobileRoute.steps}`}>
        <MobileSteps />
      </Route>
      <Route exact path={`${match.path}${MobileRoute.inviteCoworker}`}>
        <Invite sendEmails={sendEmails} />
      </Route>
      <Route exact path={`${match.path}${MobileRoute.thanks}`}>
        <Intro
          heading="Thank you!"
          text="Continue on desktop to start building stunning sites. In the meantime, learn how Framer helps companies design and launch sites of any size."
          trim
        />
        <CaseStudiesButton variant="primary" onClick={() => navigateTo("https://www.framer.com/case-studies/")}>
          View Case Studies
        </CaseStudiesButton>
      </Route>
      <Route>
        <PageNotFound />
      </Route>
    </Switch>
  )
}

const CaseStudiesButton = styled(Button)`
  margin-top: 20px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 2px 10px 0px rgba(0, 0, 0, 0.08);
  background: rgba(255, 255, 255, 0.15) !important;
`
