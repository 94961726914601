import { createBrowserHistory } from "history"
export const history = createBrowserHistory()

// Redirect to a URL route while preserving the querystring
export function redirectToRoute(path: string) {
  return history.push(`${path}${history.location.search}`)
}

// Replace the URL with the route while preserving the querystring
export function replaceWithRoute(path: string) {
  return history.replace(`${path}${history.location.search}`)
}
